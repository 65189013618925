<template>
  <div>
    <img :src="logo" alt="Logo" class="logo">
    <h1 class="mb-0 text-center">Sign Up</h1>
    <p class="text-center text-dark" style="margin-bottom:-5px">Create an account to access portal.</p>
    <div class="scrollable-form">
      <sign-up-form></sign-up-form>
    </div>
  </div>
</template>

<script>
import SignUpForm from "./Forms/SignUpForm";

export default {
  name: "SignUp",
  components: { SignUpForm },
  data() {
    return {
      logo: require('@/assets/images/Logo-C9lab eyeWhite.png')
    }
  },
  computed: {
    shouldShowLogo() {
      // Determine if the logo should be shown based on screen width
      return window.innerWidth <= 768; // Adjust this breakpoint as per your design
    }
  },
};
</script>

<style scoped>
.logo {
  display: none; /* Initially hide the logo */
}

@media screen and (max-width: 768px) {
  .logo {
    display: block; /* Show the logo on small screens */
    width: 30%; /* Adjust the width as needed */
    margin: auto; /* Center the logo */
  }
}
</style>
