<template>
  <div>
    <form class="mt-4" id="signupForm" @submit.prevent="onSubmit" ref="signupForm" autocomplete="off">
      <div class="row">
        <div class="form-group col-md-12">
          <label for="email">Email</label>
          <div class="input-with-icon">
            <span class="icon is-small is-left" style="top: 18px;">
              <i class="fas fa-envelope"></i>
            </span>
          <input type="email" v-model="user.email" class="form-control" id="email" name="email"
            @keydown="preventLeadingSpace($event, 'email')" placeholder="xyz@abc.com" maxlength="80" />
            </div>
        </div>
        <div class="form-group col-md-12">
          <label for="company">Company Name</label>
          <input type="text" v-model="user.company" class="form-control" id="company" name="company"
            placeholder="Enter Company" @keydown="preventLeadingSpace($event, 'company')" maxlength="50"
            @input="validateCompanyName" />
          <div v-if="companyError" class="error-message">{{ companyError }}</div>
        </div>
        <div class="form-group col-md-12">
          <label for="password">Password</label>
          <div class="input-with-icon">
            <input type="text" v-if="showPassword" v-model="user.password" @input="validatePassword" class="form-control"
              id="password" name="password" @keydown="preventLeadingSpace($event, 'password')" placeholder="Password"
              autocomplete="off" maxlength="20" />
            <input type="password" v-else v-model="user.password" @input="validatePassword" class="form-control"
              id="password" name="password" @keydown="preventLeadingSpace($event, 'password')" placeholder="Password"
              autocomplete="off" maxlength="20" />
            <span class="icon is-small is-right" @click="passwordtoggleShow">
              <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
            </span>
          </div>
          <p v-if="passwordError" class="error-message" style="font-size: 10px;">{{ passwordError }}</p>
        </div>
        <div class="form-group col-md-12">
          <label for="confirm_password">Confirm Password</label>
          <div class="input-with-icon">
            <input type="text" v-if="showConfirmPassword" v-model="user.confirm_password" class="form-control"
              id="confirm_password" name="confirm_password" placeholder="Confirm password"
              @keydown="preventLeadingSpace($event, 'confirm_password')" maxlength="20" />
            <input type="password" v-else v-model="user.confirm_password" class="form-control" id="confirm_password"
              name="confirm_password" placeholder="Confirm password"
              @keydown="preventLeadingSpace($event, 'confirm_password')" maxlength="20" />
            <span class="icon is-small is-right" @click="confirmpasswordtoggleShow">
              <i class="fas" :class="{ 'fa-eye-slash': showConfirmPassword, 'fa-eye': !showConfirmPassword }"></i>
            </span>
          </div>
        </div>
        <div class="form-group col-md-12">
          <label for="timezone">Time Zone</label>
          <select class="form-control" id="timezone" name="timezone" v-model="user.timezone">
            <option :value="defaultTimezone" selected disabled>Select timezone</option>
            <option v-for="timezone in timezones" :key="timezone.label">
              {{ timezone.label }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-12">
          <label for="countrycodes">Country codes</label>
          <select class="form-control" id="countrycode" name="countrycode" v-model="user.countrycode">
            <option :value="defaultCountryCode" selected disabled>Select Country codes</option>
            <option v-for="countrycode in countrycodes" :key="countrycode.key">
              {{ countrycode.label }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-12">
          <label for="mobile_number">Mobile Number</label>
          <input type="text" v-model="user.mobile_number" class="form-control" id="mobile_number" name="mobile_number"
            placeholder=" Mobile Number" @keydown="preventLeadingSpace($event, 'mobile_number')" maxlength="10" />
        </div>
        
        <div class="d-inline-block w-100 mt-2 ml-3">
          <button :disabled="isSubmitting" type="submit" class="btn btn-primary float-left mr-2">
            {{ isSubmitting ? 'Submitting...' : 'Sign Up' }}
          </button>
          <button type="reset" class="btn btn-primary float-left">Cancel</button>
        </div>
        <div class="d-inline-block w-100 mt-2 ml-3">
          <p class="float-left"> <span class="dark-color"> Already have an account? </span>
            <a href="/auth/sign-in" class="iq-waves-effect pr-4"> Sign in </a>
          </p>
        </div>
        <div>
        </div>
      </div>
    </form>
  </div>
</template>


<script>
import axios from "axios";
import constant, { toastMessage } from "@/config/constant";
import "jquery-validation";

export default {
  name: "SignUpForm",
  props: ["email", "password", "timezone", "mobile_number", "country_code", "company"],
  data() {
    return {
      isSubmitting: false, // Define isSubmitting in the data object
      passwordError: '',
      confirmPasswordError: '',
      showPassword: false,
      showConfirmPassword: false,
      companyError: '',
      defaultTimezone: 'Select Time Zone',
      defaultCountryCode: 'Select Country Code',
      user: {
        email: "",
        password: "",
        confirm_password: "",
        timezone: 'Select Time Zone',
        mobile_number: "",
        countrycode: "",
        company: ""
      },
      timezones: [],
      countrycodes: [],
      message: "",
    };
  },

  mounted() {
    this.fetchtimezones();
    this.fetchcountrycodes();
    this.user.email = this.$props.email;
    this.user.company = this.$props.company;
    this.user.password = this.$props.password;
    this.user.timezone = this.$props.timezone || 'Select Time Zone';

    this.user.mobile_number = this.$props.mobile_number;
    this.user.countrycode = this.$props.countrycode || 'Select Country Code';

    //Form Validation
    $(this.$refs.signupForm).validate({
      rules: {
        email: {
          required: true,
          email: true,
        },
        company: {
          required: true,
        },
        password: {
          required: true,
        },
        confirm_password: {
          required: true,
          equalTo: "#password",
        },
        timezone: {
          required: true,
        },
        countrycode: {
          required: true,
        },
        mobile_number: {
          required: true,
          digits: true,
          minlength: 10,
          maxlength: 10,
        },
      },
      messages: {
        email: {
          required: "Email is required",
          email: "Please enter a valid email",
        },
        company: {
          required: "Company name is required",
        },
        password: {
          required: "password is required",
        },

        confirm_password: {
          required: "Please confirm your password",
          equalTo: "Passwords do not match",
        },
        timezone: {
          required: "Please select a time zone",
        },
        countrycode: {
          required: "Please select a country code",
        },
        mobile_number: {
          required: "Mobile number is required",
          digits: "Please enter only digits",
          minlength: "Mobile number should be exactly 10 digits",
          maxlength: "Mobile number should be exactly 10 digits",
        },
      },
    });
  },
  computed: {
    hasErrors() {
      return this.passwordError || this.confirmPasswordError || this.companyError || !this.user.email || !this.user.password || !this.user.confirm_password || !this.user.timezone || !this.user.mobile_number || !this.user.countrycode || !this.user.company;
    },

  },
  methods: {
    // Allowing only alphabetic characters and alphanumeric characters
    validateCompanyName() {
      const regex = /^\d+$/;
      if (regex.test(this.user.company)) {
        this.companyError = "Company name cannot consist solely of numbers.";
      } else {
        this.companyError = '';
      }
    },
    // First letter space not allowed
    preventLeadingSpace(event, field) {
      if (event.key === ' ' && (event.target.selectionStart === 0 || this.user[field] === '')) {
        event.preventDefault();
      } else {
        this.user[field] = event.target.value.trim();
      }
    },
    // Strong password
    validatePassword() {
      const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{5,}$/;
      if (!regex.test(this.user.password)) {
        this.passwordError = "Password  Min 5 chars, 1 letter, 1 number, 1 special";
      } else {
        this.passwordError = '';
      }
    },
    // password and confirmpassword check
    validateConfirmPassword() {
      if (this.user.password !== this.user.confirm_password) {
        this.confirmPasswordError = "Passwords do not match";
      } else {
        this.confirmPasswordError = '';
      }
    },
    // Fetch time zone
    fetchtimezones() {
      axios
        .get(constant.API_BASE_URL + "timezones/")
        .then((response) => {
          const uniqueTimezones = new Set(
            response.data.data.map((timezone) => timezone.label)
          );
          this.timezones = Array.from(uniqueTimezones).map((label) => ({
            label,
          }));
        })
        .catch((error) => {
          console.error("Error fetching time zones:", error);
        });
    },
    //Fetch country codes
    fetchcountrycodes() {
      axios
        .get(constant.API_BASE_URL + "countrycodes/")
        .then((response) => {
          this.countrycodes = response.data.data.map((item) => ({
            ...item,
            key: item.value + "_" + item.label,
          }));
        })
        .catch((error) => {
          console.error("Error fetching country codes:", error);
        });
    },
    //Password eye icon show and hide
    passwordtoggleShow() {
      this.showPassword = !this.showPassword;
    },
     //Confirm password eye icon show and hide
    confirmpasswordtoggleShow() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    //signup form submit
    onSubmit() {
      this.validateCompanyName();
      this.validateConfirmPassword();
      this.validateCompanyName();
      if (this.hasErrors || !$(this.$refs.signupForm).valid()) {
        return;
      }
      this.isSubmitting = true; // Disable the submit button
      const selectedTimezone = this.timezones.find(
        (timezone) => timezone.label === this.user.timezone
      );
      const selectedCountryCode = this.countrycodes.find(
        (countrycode) => countrycode.label === this.user.countrycode
      );
      if (!selectedTimezone || !selectedCountryCode) {
        this.isSubmitting = false; // Enable the submit button
        return;
      }
      const timezoneId = selectedTimezone.label;
      const countrycodeId = selectedCountryCode.value;

      axios
        .post(constant.API_BASE_URL + "signup/", {
          email: this.user.email,
          company: this.user.company,
          password: this.user.password,
          timezone: timezoneId,
          mobile_number: this.user.mobile_number,
          country_code: countrycodeId,
        })
        .then((response) => {
          this.message = response.data.message;
          this.icon = "success";
          toastMessage(this.icon, this.message);
          this.user = {
            email: "",
            company: "",
            password: "",
            confirm_password: "",
            timezone: "",
            mobile_number: "",
            countrycode: "",
          };
        })
        .catch((error) => {
          if (error.response && error.response.status === 400 && error.response.data.message) {
            this.message = error.response.data.message;
            this.icon = "error";
            toastMessage(this.icon, this.message);
          } else if (error.response && error.response.status === 400) {
            this.message = error.response.data.email;
            this.icon = "error";
            toastMessage(this.icon, this.message);
          } else {
            console.error("There was an error!", error);
          }
        })
        .finally(() => {
          this.isSubmitting = false; // Enable the submit button
        });
      this.message = "";
    },
  },
};
</script>